import React, { useState } from 'react';

const StoreForm = ({
  selectedStore,
  handleInputChange,
  handleSave,
  geocodeAddress,
}) => {
  const [geocoding, setGeocoding] = useState(false);  // Loading state for geocoding
  const [geoError, setGeoError] = useState(null);  // Error state for geocoding

  const handleSaveWithGeocoding = async () => {
    setGeocoding(true);
    setGeoError(null);
    try {
      const location = await geocodeAddress(selectedStore.address);  // Geocode the address
      if (location) {
        const updatedStore = { ...selectedStore, location };  // Add the geocoded location
        handleSave(updatedStore);
      }
    } catch (error) {
      setGeoError('Failed to geocode address');
    } finally {
      setGeocoding(false);
    }
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      {selectedStore ? (
        <div className="space-y-4">
          <h2 className="text-xl font-bold">Modifier le magasin</h2>
          <label className="block">
            Nom :
            <input
              type="text"
              name="name"
              value={selectedStore.name}
              onChange={handleInputChange}
              className="w-full p-2 mt-1 border border-gray-300 rounded"
            />
          </label>
          <label className="block">
            Adresse :
            <input
              type="text"
              name="address"
              value={selectedStore.address}
              onChange={handleInputChange}
              className="w-full p-2 mt-1 border border-gray-300 rounded"
            />
          </label>
          <label className="block">
            Marque :
            <input
              type="text"
              name="brand"
              value={selectedStore.brand || ''}
              onChange={handleInputChange}
              className="w-full p-2 mt-1 border border-gray-300 rounded"
            />
          </label>
          <label className="block">
            Image URL :
            <input
              type="text"
              name="image"
              value={selectedStore.image || ''}
              onChange={handleInputChange}
              className="w-full p-2 mt-1 border border-gray-300 rounded"
            />
          </label>

          {geoError && <p className="text-red-600">{geoError}</p>}
          <button
            onClick={handleSaveWithGeocoding}
            disabled={geocoding}
            className={`mt-4 p-2 bg-purple-600 text-white rounded hover:bg-purple-700 ${geocoding && 'opacity-50 cursor-not-allowed'}`}
          >
            {geocoding ? 'Géocodage...' : 'Enregistrer'}
          </button>
        </div>
      ) : (
        <p>Sélectionnez un magasin à modifier</p>
      )}
    </div>
  );
};

export default StoreForm;
