import React, { useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import StoreSearchBox from '../features/search/components/StoreSearchBox';
import StoreGrid from '../features/search/components/StoreGrid';
import StoreModal from '../features/search/components/StoreModal';
import { ErrorMessage, LoadingMessage } from '../components/Messages';
import { UseSearchStores } from '../features/search/hooks/UseSearchStores';

const StorePage = () => {
  const { stores, error, loading, search } = UseSearchStores();
  const [selectedStore, setSelectedStore] = useState(null);

  const handleStoreClick = (store) => {
    setSelectedStore(store); // Open modal with selected store info
  };

  const handleCloseModal = () => {
    setSelectedStore(null); // Close modal
  };

  return (
    <div className="flex flex-col min-h-screen bg-white">
      <Header />
      
      {/* Main Content */}
      <div className="flex-grow container mx-auto py-8">
        <StoreSearchBox onSearch={search} />
        <ErrorMessage error={error} />
        <LoadingMessage loading={loading} />
        {!loading && !error && (
          <div className="mt-8">
            <StoreGrid stores={stores} onStoreClick={handleStoreClick} />
          </div>
        )}
      </div>
      
      {/* Footer at the Bottom */}
      <Footer />

      {selectedStore && (
        <StoreModal store={selectedStore} onClose={handleCloseModal} />
      )}
    </div>
  );
};

export default StorePage;
