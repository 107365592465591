import React, { useEffect, useState } from 'react';

const ProductModal = ({ product, onClose }) => {
  const [yatils, setYatils] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
      // Fetch Yatils near the user's location
  const fetchYatils = async () => {
    try {
      // Use Geolocation API to get user's current location
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(async (position) => {
          const { latitude, longitude } = position.coords;
          const response = await fetch(`/.netlify/functions/getYatilsByProduct?productId=${product._id}&lat=${latitude}&lon=${longitude}`);
          const yatilsData = await response.json();
          setYatils(yatilsData);
          setLoading(false);
        });
      } else {
        setError('La géolocalisation n\'est pas supportée par votre navigateur.');
        setLoading(false);
      }
    } catch (error) {
      setError('Une erreur est survenue lors de la récupération des Yatils.');
      setLoading(false);
    }
  };
    fetchYatils();
  }, [product._id]);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-lg p-6">
        <button className="text-right text-red-600 mb-2" onClick={onClose}>Fermer ✖</button>
        <h2 className="text-2xl font-bold mb-4">{product.name}</h2>
        <img src={product.image || 'https://dummyimage.com/300x300/E0D7FF/000.png&text='+encodeURI(product.name)} alt={product.name} className="w-full h-auto rounded-lg mb-4" />
        <p className="text-lg mb-4"><strong>Marque:</strong> {product.brand}</p>
        <p className="text-lg mb-4"><strong>Quantité:</strong> {product.quantity}</p>
        <p className="text-lg mb-4"><strong>Description:</strong> {product.description}</p>

        <h3 className="text-xl font-semibold mb-4">Yatils près de votre position :</h3>

        {loading && <p>Chargement des Yatils...</p>}
        {error && <p className="text-red-500">{error}</p>}
        {!loading && yatils.length === 0 && !error && <p>Aucun Yatil trouvé près de votre position.</p>}
        <ul className="space-y-2">
          {yatils.map((yatil, index) => (
            <li key={index} className="border rounded-lg p-2">
              <strong>{yatil.store.name}</strong> - {yatil.store.address}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ProductModal;
