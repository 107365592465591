import React from 'react';

export const ErrorMessage = ({ error }) => error && (
  <p className="text-red-500 bg-red-100 border border-red-500 p-3 rounded">
    {error}
  </p>
);

export const LoadingMessage = ({ loading }) => loading && (
  <p className="text-blue-500 bg-blue-100 p-3 rounded">
    Chargement...
  </p>
);
