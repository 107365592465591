import React from 'react';
import Header from '../components/Header';
import Tabs from '../components/Tabs';  // The tab system
import ProductManager from '../features/admin/components/productmanager/ProductManager';  // The product management tab
import StoreManager from '../features/admin/components/storemanager/StoreManager';  // The store management tab
import YatilManager from '../features/admin/components/yatilmanager/YatilManager';

const AdminPage = () => {
  const tabs = [
    { label: 'Produits', content: <ProductManager /> },
    { label: 'Magasins', content: <StoreManager /> }, 
    { label: 'Yatils', content: <YatilManager /> }, 
  ];

  return (
    <div className="admin-page min-h-screen bg-gray-100">
      <Header />
      <div className="container mx-auto py-8">
        <Tabs tabs={tabs} />
      </div>
    </div>
  );
};

export default AdminPage;
