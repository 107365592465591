import React, { useState } from 'react';

const Tabs = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(tabs[0].label);

  return (
    <div className="tabs">
      {/* Tab buttons */}
      <div className="flex justify-center mb-6">
        {tabs.map((tab) => (
          <button
            key={tab.label}
            className={`px-4 py-2 mx-2 rounded ${
              activeTab === tab.label
                ? 'bg-blue-500 text-white'
                : 'bg-gray-200 text-gray-700'
            } hover:bg-blue-400 transition duration-200 ease-in-out`}
            onClick={() => setActiveTab(tab.label)}
          >
            {tab.label}
          </button>
        ))}
      </div>

      {/* Tab content */}
      <div className="tab-content">
        {tabs.map((tab) => {
          if (tab.label === activeTab) {
            return (
              <div key={tab.label} className="p-4 bg-white shadow-md rounded">
                {tab.content}
              </div>
            );
          } else {
            return null;
          }
        })}
      </div>
    </div>
  );
};

export default Tabs;
