import React from 'react';
import ParentSearch from './ParentSearch';

const ProductForm = ({
  selectedProduct,
  handleInputChange,
  handleCheckboxChange,
  handleSave,
  parentSearch,
  searchResults,
  handleParentSearch,
  handleParentSelect,
}) => {
  return (
    <div className="product-edit">
      {selectedProduct ? (
        <div className="space-y-4">
          <h2 className="text-lg font-semibold">Modifier le produit</h2>
          <label className="block">
            Nom:
            <input
              className="mt-2 p-2 border border-gray-300 rounded w-full"
              type="text"
              name="name"
              value={selectedProduct.name}
              onChange={handleInputChange}
            />
          </label>
          <label className="block">
            Quantité:
            <input
              className="mt-2 p-2 border border-gray-300 rounded w-full"
              type="text"
              name="quantity"
              value={selectedProduct.quantity}
              onChange={handleInputChange}
            />
          </label>
          <label className="block">
            Description:
            <textarea
              className="mt-2 p-2 border border-gray-300 rounded w-full"
              name="description"
              value={selectedProduct.description}
              onChange={handleInputChange}
            />
          </label>
          <label className="block">
            Image:
            <input
              className="mt-2 p-2 border border-gray-300 rounded w-full"
              type="text"
              name="image"
              value={selectedProduct.image}
              onChange={handleInputChange}
            />
          </label>
          <label className="block">
            Concret:
            <input
              className="mt-2 mr-2"
              type="checkbox"
              name="concrete"
              checked={selectedProduct.concrete}
              onChange={handleCheckboxChange}
            />
            <span>Concret</span>
          </label>
          <label className="block">
            Chemin (non modifiable):
            <input
              className="mt-2 p-2 border border-gray-300 rounded w-full"
              type="text"
              name="path"
              value={selectedProduct.path}
              readOnly
            />
          </label>

          <ParentSearch
            parentSearch={parentSearch}
            searchResults={searchResults}
            handleParentSearch={handleParentSearch}
            handleParentSelect={handleParentSelect}
          />

          <button
            onClick={() => handleSave(selectedProduct)}
            className="mt-4 px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700"
          >
            Enregistrer
          </button>
        </div>
      ) : (
        <p>Sélectionnez un produit à modifier</p>
      )}
    </div>
  );
};

export default ProductForm;
