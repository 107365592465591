import { useState } from 'react';

export const UseSearchStores = () => {
  const [stores, setStores] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const search = async (storeQuery) => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(`/.netlify/functions/getStores?name=${encodeURIComponent(storeQuery)}`);
      if (!response.ok) {
        throw new Error('Failed to search stores');
      }
      const storesData = await response.json();
      setStores(storesData);
    } catch (error) {
      setError('Une erreur est survenue lors de la récupération des magasins.');
    } finally {
      setLoading(false);
    }
  };

  return { stores, error, loading, search };
};
