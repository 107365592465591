import { useState, useEffect, useCallback } from 'react';

export const UseAdminProducts = () => {
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showParentless, setShowParentless] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [parentSearch, setParentSearch] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchProducts = useCallback(async () => {
    try {
      const query = showParentless ? '&parentless=true' : '';
      const response = await fetch(`/.netlify/functions/getProducts?limit=20${query}`);
      if (!response.ok) {
        throw new Error(`Failed to fetch products: ${response.statusText}`);
      }
      const data = await response.json();
      setProducts(data);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  }, [showParentless]);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  

  const handleParentSearch = async (e) => {
    setParentSearch(e.target.value);
    try {
      const response = await fetch(`/.netlify/functions/getProducts?name=${encodeURIComponent(e.target.value)}&limit=5`);
      if (!response.ok) {
        throw new Error('Failed to fetch products for parent selection');
      }
      const data = await response.json();
      setSearchResults(data);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleSave = async (updatedProduct) => {
    try {
      const response = await fetch(`/.netlify/functions/updateProduct?id=${updatedProduct._id}&updatePaths=true`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedProduct),
      });
      console.log(response);
      if (!response.ok) {
        throw new Error('Failed to update product');
      }
      fetchProducts();
      setSelectedProduct(null);
    } catch (error) {
      setError(error.message);
    }
  };

  return {
    products,
    selectedProduct,
    showParentless,
    setShowParentless,
    searchResults,
    parentSearch,
    setSelectedProduct,
    setSearchResults,
    handleParentSearch,
    handleSave,
    loading,
    error,
  };
};
