import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import LandingPage from './pages/LandingPage';
import AdminPage from './pages/AdminPage';
import ProductPage from './pages/ProductPage';
import StorePage from './pages/StorePage';
import FeedbackWidget from './features/dev/FeedbackWidget';

function App() {
  return (
    <>
    <Router>
      <Routes>
        <Route path="/admin" element={<AdminPage/>} />
        <Route path="/" element={<LandingPage/>} />
        <Route path="/products" element={<ProductPage/>} />
        <Route path="/stores" element={<StorePage/>} />
        <Route path="*" element={<h1>404 Not Found</h1>} />
      </Routes>
    </Router>
    <FeedbackWidget/>
    </>
  );
}

export default App;