import React, { useState } from 'react';

const FeedbackWidget = () => {
  const [expanded, setExpanded] = useState(false);
  const [comment, setComment] = useState('');
  const [error, setError] = useState(null);
  const [submitted, setSubmitted] = useState(false);

  const handleSend = async () => {
    if (!comment.trim()) {
      setError('Le commentaire ne peut pas être vide.');
      return;
    }

    setError(null);

    try {
      const response = await fetch('/.netlify/functions/createComment', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ content: comment })
      });

      if (response.ok) {
        setComment(''); // Clear the input field
        setSubmitted(true);
        setTimeout(() => setSubmitted(false), 2000); // Auto-hide success message after 2 seconds
      } else {
        throw new Error('Échec de l\'envoi du commentaire.');
      }
    } catch (error) {
      setError('Une erreur est survenue lors de l\'envoi du commentaire.');
    }
  };

  return (
    <div className={`fixed bottom-4 right-4 bg-white shadow-lg rounded-lg p-4 'w-60' transition-all duration-300`}>
      {!expanded ? (
        <button
          onClick={() => setExpanded(true)}
          className="flex items-center space-x-2 text-purple-700 hover:text-purple-900"
        >
          <span>💬</span>
          <span>Un commentaire ?</span>
        </button>
      ) : (
        <>
          <textarea
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            placeholder="Un commentaire, un problème rencontré, une suggestion ? N'hésitez pas !"
            className="w-full h-24 p-2 mb-2 border border-gray-300 rounded resize-none focus:outline-none focus:ring-2 focus:ring-purple-500"
          />
          <div className="flex items-center justify-between">
            <button
              onClick={handleSend}
              className="bg-purple-600 text-white py-1 px-4 rounded hover:bg-purple-700"
            >
              Envoyer
            </button>
            <button
              onClick={() => setExpanded(false)}
              className="text-red-600 hover:text-red-800"
            >
              ✖
            </button>
          </div>
          {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
          {submitted && <p className="text-green-500 text-sm mt-2">Commentaire envoyé !</p>}
        </>
      )}
    </div>
  );
};

export default FeedbackWidget;
