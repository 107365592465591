import React from 'react';
import StoreList from './StoreList';
import StoreForm from './StoreForm';
import { ErrorMessage, LoadingMessage } from '../../../../components/Messages';
import { UseAdminStores } from '../../hooks/UseAdminStores';

const StoreManager = () => {
  const {
    stores,
    selectedStore,
    setSelectedStore,
    handleSave,
    geocodeAddress,  // Geocoding function
    loading,
    error,
  } = UseAdminStores();

  // Handle edit click
  const handleEditClick = (store) => {
    setSelectedStore({ ...store });
  };

  // Handle input change for store form
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedStore((prevStore) => ({
      ...prevStore,
      [name]: value,
    }));
  };

  return (
    <div className="flex flex-col md:flex-row gap-6 p-6">
      <div className="md:w-2/3">
        <ErrorMessage error={error} />
        <LoadingMessage loading={loading} />
        <StoreList stores={stores} handleEditClick={handleEditClick} />
      </div>
      <div className="md:w-1/3">
        <StoreForm
          selectedStore={selectedStore}
          handleInputChange={handleInputChange}
          handleSave={handleSave}
          geocodeAddress={geocodeAddress}  // Pass the geocode function
        />
      </div>
    </div>
  );
};

export default StoreManager;
