import React, { useState } from 'react';

const Hero = ({ onSearch }) => {
  const [product, setProduct] = useState('');
  const [location, setLocation] = useState('');
  const [geocodingError, setGeocodingError] = useState(null);  // State for error handling

  const geocodeLocation = async (address) => {
    try {
      const geocodingApiUrl = `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(address)}&format=json&limit=1`;
      const response = await fetch(geocodingApiUrl);
      const data = await response.json();

      if (data && data.length > 0) {
        const { lat, lon } = data[0];  // Extract latitude and longitude
        return { latitude: parseFloat(lat), longitude: parseFloat(lon) };
      } else {
        throw new Error('Address not found');
      }
    } catch (error) {
      setGeocodingError('Failed to geocode the location. Please try again.');
      return null;
    }
  };

  const handleSearch = async () => {
    setGeocodingError(null);
    
    if (location) {
      const coords = await geocodeLocation(location);
      if (coords) {
        onSearch(product, coords.latitude, coords.longitude);
      }
    } else {
      onSearch(product, null, null);
    }
  };

  return (
    <section className="hero bg-cover bg-center py-20 text-center">
      <div className="bg-white bg-opacity-90 p-8 rounded-lg max-w-lg mx-auto">
        <h1 className="text-4xl font-bold mb-6">Trouvez ce que vous cherchez près de chez vous</h1>
        <div className="space-y-4">
          <input
            type="text"
            placeholder="Quel produit cherchez-vous ?"
            value={product}
            onChange={(e) => setProduct(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-lg"
          />
          <input
            type="text"
            placeholder="Entrez une adresse ou une ville"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-lg"
          />
          <button
            onClick={handleSearch}
            className="w-full py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700"
          >
            Rechercher
          </button>

          {/* Display error message if geocoding fails */}
          {geocodingError && <p className="text-red-500 mt-2">{geocodingError}</p>}
        </div>
      </div>
    </section>
  );
};

export default Hero;
