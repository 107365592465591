import React, { useEffect, useState } from 'react';

const StoreModal = ({ store, onClose }) => {
  const [yatils, setYatils] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

 
  useEffect(() => {
     // Fetch Yatils for the store
  const fetchYatilsForStore = async () => {
    try {
      const response = await fetch(`/.netlify/functions/getYatilsByStore?storeId=${store._id}`);
      const yatilsData = await response.json();
      setYatils(yatilsData);
      setLoading(false);
    } catch (error) {
      setError('Une erreur est survenue lors de la récupération des Yatils.');
      setLoading(false);
    }
  };
    fetchYatilsForStore();
  }, [store._id]);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-lg p-6">
        <button className="text-right text-red-600 mb-2" onClick={onClose}>Fermer ✖</button>
        <h2 className="text-2xl font-bold mb-4">{store.name}</h2>
        <p className="text-lg mb-4"><strong>Adresse:</strong> {store.address}</p>
        <p className="text-lg mb-4"><strong>Marque:</strong> {store.brand}</p>
        <img src={store.image || 'https://dummyimage.com/300x300/E0D7FF/000.png&text='+encodeURI(store.name)} alt={store.name} className="w-full h-auto rounded-lg mb-4" />

        <h3 className="text-xl font-semibold mb-4">Produits disponibles dans ce magasin :</h3>

        {loading && <p>Chargement des produits...</p>}
        {error && <p className="text-red-500">{error}</p>}
        {!loading && yatils.length === 0 && !error && <p>Aucun produit trouvé dans ce magasin.</p>}
        <ul className="space-y-2">
          {yatils.map((yatil, index) => (
            <li key={index} className="border rounded-lg p-2">
              <strong>{yatil.product.name}</strong> - {yatil.product.description}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default StoreModal;
