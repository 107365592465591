import { useState } from 'react';

export const UseSearchYatils = () => {
  const [results, setResults] = useState([]);  // Triplets of { Yatil, Product, Store }
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const search = async (product, latitude, longitude, maxDistance = 5000) => {
    setLoading(true);
    setError(null);
    try {
      // Construct the URL with query parameters for product and location
      let yatilUrl = `/.netlify/functions/searchYatils?query=${encodeURIComponent(product)}&latitude=${encodeURIComponent(latitude)}&longitude=${encodeURIComponent(longitude)}&maxDistance=${encodeURIComponent(maxDistance)}`;

      const yatilResponse = await fetch(yatilUrl);
      if (!yatilResponse.ok) {
        throw new Error(`Yatil search failed: ${yatilResponse.statusText}`);
      }

      const yatilData = await yatilResponse.json();  // This should be a list of { Yatil, Product, Store }
      setResults(yatilData);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Une erreur est survenue lors de la récupération des données.');
    } finally {
      setLoading(false);
    }
  };

  return { results, error, loading, search };
};
