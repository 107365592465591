import React from 'react';
import ProductList from './ProductList';
import ProductForm from './ProductForm';
import { ErrorMessage, LoadingMessage } from '../../../../components/Messages';
import { UseAdminProducts } from '../../hooks/UseAdminProducts';

const ProductManager = () => {
  const {
    products,
    selectedProduct,
    showParentless,
    setShowParentless,
    searchResults,
    parentSearch,
    setSelectedProduct,
    setSearchResults,
    handleParentSearch,
    handleSave,
    loading,
    error,
  } = UseAdminProducts();

  // Handle edit click
  const handleEditClick = (product) => {
    setSelectedProduct({ ...product });
  };

  // Handle input change for product form
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedProduct((prevProduct) => ({
      ...prevProduct,
      [name]: value,
    }));
  };

  // Handle checkbox change for product form
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setSelectedProduct((prevProduct) => ({
      ...prevProduct,
      [name]: checked,
    }));
  };

  return (
    <div className="admin-container p-6">
      {/* Checkbox to toggle between all products and parentless products */}
      <label className="block mb-4">
        <input
          className="mr-2"
          type="checkbox"
          checked={showParentless}
          onChange={() => setShowParentless(!showParentless)}
        />
        Afficher uniquement les produits sans parent
      </label>

      <ErrorMessage error={error} />
      <LoadingMessage loading={loading} />

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <ProductList products={products} handleEditClick={handleEditClick} />
        <ProductForm
          selectedProduct={selectedProduct}
          handleInputChange={handleInputChange}
          handleCheckboxChange={handleCheckboxChange}
          handleSave={handleSave}
          parentSearch={parentSearch}
          searchResults={searchResults}
          handleParentSearch={handleParentSearch}
          handleParentSelect={(parentProduct) => {
            setSelectedProduct((prevProduct) => ({
              ...prevProduct,
              parent: parentProduct._id,
              path: `${parentProduct.path} > ${prevProduct.name}`,
            }));
            setSearchResults([]);
          }}
        />
      </div>
    </div>
  );
};

export default ProductManager;
