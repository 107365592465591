import React from 'react';

const StoreList = ({ stores, handleEditClick }) => (
  <div className="overflow-x-auto">
    <table className="min-w-full bg-white border border-gray-300">
      <thead className="bg-gray-100">
        <tr>
          <th className="py-2 px-4 border-b">Nom</th>
          <th className="py-2 px-4 border-b">Adresse</th>
          <th className="py-2 px-4 border-b">Actions</th>
        </tr>
      </thead>
      <tbody>
        {stores.map((store) => (
          <tr key={store._id} className="hover:bg-gray-50">
            <td className="py-2 px-4 border-b">{store.name}</td>
            <td className="py-2 px-4 border-b">{store.address}</td>
            <td className="py-2 px-4 border-b">
              <button
                onClick={() => handleEditClick(store)}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                Modifier
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

export default StoreList;
