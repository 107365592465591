import React from 'react';

const ProductGrid = ({ products, onProductClick }) => (
  <section className="py-12 bg-gray-100">
    <div className="container mx-auto grid grid-cols-2 sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-8 gap-6">
      {products.map((productItem, index) => (
        <div
          key={index}
          className="bg-white shadow-md rounded-lg p-4 text-center border border-gray-200 cursor-pointer"
          onClick={() => onProductClick(productItem)}
        >
          <img
            src={productItem.image || 'https://dummyimage.com/300x300/E0D7FF/000.png&text='+encodeURI(productItem.name)}
            alt={`Product ${productItem.name}`}
            className="w-full h-auto rounded-lg mb-4"
          />
          <h3 className="text-lg font-semibold text-gray-800">{productItem.name} {productItem.brand}</h3>
          {productItem.quantity && <p className="text-gray-600"><strong>Quantité:</strong> {productItem.quantity}</p>}
          {productItem.description && <p className="text-gray-600"><strong>Description:</strong> {productItem.description}</p>}
        </div>
      ))}
    </div>
  </section>
);

export default ProductGrid;
