import React, { useState } from 'react';

const ProductSearchBox = ({ onSearch, /*filters, setFilters*/ }) => {
  const [name, setName] = useState('');
  const [brand, setBrand] = useState('');

  const handleSearch = () => {
    // Pass search query and filters to the onSearch handler
    onSearch({ name, brand });
  };

  /*const handleFilterChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };
*/
  return (
    <div className="flex flex-col space-y-4 mb-6">
      <div className="flex flex-col">
        <label className="text-lg font-medium mb-2">Nom du produit:</label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Rechercher par nom"
          className="p-2 border border-gray-300 rounded-lg"
        />
      </div>

      <div className="flex flex-col">
        <label className="text-lg font-medium mb-2">Marque:</label>
        <input
          type="text"
          value={brand}
          onChange={(e) => setBrand(e.target.value)}
          placeholder="Rechercher par marque"
          className="p-2 border border-gray-300 rounded-lg"
        />
      </div>

      <div>
        <button
          onClick={handleSearch}
          className="px-6 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700"
        >
          Rechercher
        </button>
      </div>
    </div>
  );
};

export default ProductSearchBox;
