import React from 'react';
import Header from '../components/Header';
import SearchBox from '../features/search/components/SearchBox';
import YatilGrid from '../features/search/components/YatilGrid';
import Footer from '../components/Footer';
import { ErrorMessage, LoadingMessage } from '../components/Messages';
import { UseSearchYatils } from '../features/search/hooks/UseSearchYatils';

const LandingPage = () => {
  const { results, error, loading, search } = UseSearchYatils();
  
  return (
    <div className="landing-container min-h-screen bg-gray-50 flex flex-col justify-between">
      <Header />
      <div className="container mx-auto py-8">
        <SearchBox onSearch={search} />
        <ErrorMessage error={error} />
        <LoadingMessage loading={loading} />
        {!loading && !error && (
          <div className="mt-8">
            <YatilGrid yatilTriplets={results} />
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default LandingPage;
