import React, { useState } from 'react';

const ProductSearch = ({ onProductSelect }) => {
  const [productQuery, setProductQuery] = useState('');
  const [productResults, setProductResults] = useState([]);

  const handleProductSearch = async (e) => {
    setProductQuery(e.target.value);
    if (e.target.value.length > 2) {
      const response = await fetch(`/.netlify/functions/searchProducts?name=${encodeURIComponent(e.target.value)}`);
      const products = await response.json();
      setProductResults(products);
    } else {
      setProductResults([]);
    }
  };

  return (
    <div className="mb-4">
      <input
        type="text"
        placeholder="Rechercher un produit..."
        value={productQuery}
        onChange={handleProductSearch}
        className="w-full px-4 py-2 border rounded-lg border-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-500"
      />
      <ul className="mt-2">
        {productResults.map((product) => (
          <li
            key={product._id}
            onClick={() => onProductSelect(product)}
            className="cursor-pointer py-2 px-4 hover:bg-gray-200 rounded-lg"
          >
            {product.name} - {product.description}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ProductSearch;
