import React, { useState } from 'react';

const StoreSearchBox = ({ onSearch }) => {
  const [storeQuery, setStoreQuery] = useState('');

  const handleSearch = () => {
    onSearch(storeQuery);
  };

  return (
    <div className="mb-4">
      <input
        type="text"
        placeholder="Rechercher un magasin..."
        value={storeQuery}
        onChange={(e) => setStoreQuery(e.target.value)}
        className="w-full px-4 py-2 border rounded-lg border-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-500"
      />
      <button
        onClick={handleSearch}
        className="mt-2 bg-purple-600 text-white py-2 px-4 rounded-lg hover:bg-purple-700"
      >
        Rechercher
      </button>
    </div>
  );
};

export default StoreSearchBox;
