import React, { useState, useEffect } from 'react';
import StoreSearch from './StoreSearch';
import ProductSearch from './ProductSearch';

const YatilManager = () => {
  const [selectedStore, setSelectedStore] = useState(null);
  const [productsInStore, setProductsInStore] = useState([]);

  const fetchYatilsForStore = async (storeId) => {
    const response = await fetch(`/.netlify/functions/getProductsByStore?storeId=${storeId}`);
    const yatilData = await response.json();
    setProductsInStore(yatilData.products);
  };

  useEffect(() => {
    if (selectedStore) {
      fetchYatilsForStore(selectedStore._id);
    }
  }, [selectedStore]);

  const createYatil = async (product) => {
    const response = await fetch('/.netlify/functions/createYatil', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        productId: product._id,
        storeId: selectedStore._id,
        productPath: product.path,
        location: selectedStore.location,
      }),
    });

    if (response.ok) {
      fetchYatilsForStore(selectedStore._id);
    }
  };

  const deleteYatil = async (productId) => {
    const response = await fetch('/.netlify/functions/deleteYatil', {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        productId,
        storeId: selectedStore._id,
      }),
    });

    if (response.ok) {
      fetchYatilsForStore(selectedStore._id);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-2xl font-bold mb-4">Yatil Manager</h2>

      <StoreSearch onStoreSelect={setSelectedStore} />

      {selectedStore && (
        <>
          <h3 className="text-xl font-semibold mb-4">Produits dans {selectedStore.name}:</h3>
          <ul className="mb-6">
            {productsInStore.length > 0 ? (
              productsInStore.map((product) => (
                <li
                  key={product._id}
                  className="flex justify-between items-center py-2 border-b border-gray-300"
                >
                  {product.name} - {product.description}
                  <button
                    onClick={() => deleteYatil(product._id)}
                    className="ml-4 text-red-600 hover:text-red-800"
                  >
                    🗑️
                  </button>
                </li>
              ))
            ) : (
              <p>Aucun produit trouvé dans ce magasin.</p>
            )}
          </ul>

          <h3 className="text-xl font-semibold mb-4">Ajouter un produit à {selectedStore.name}:</h3>
          <ProductSearch onProductSelect={createYatil} />
        </>
      )}
    </div>
  );
};

export default YatilManager;
