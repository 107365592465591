import { useState } from 'react';

export const UseSearchProducts = () => {
  const [products, setProducts] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const search = async ({ name, brand }) => {
    setLoading(true);
    setError(null);
    try {
      // Construct the API URL with query parameters
      let query = '';
      if (name) query += `name=${encodeURIComponent(name)}&`;
      if (brand) query += `brand=${encodeURIComponent(brand)}&`;

      const productUrl = `/.netlify/functions/searchProducts?${query}`;  // Assuming this is the correct Netlify function

      const response = await fetch(productUrl);
      if (!response.ok) {
        throw new Error(`Product search failed: ${response.statusText}`);
      }

      const data = await response.json();
      setProducts(data);  // Set the fetched products to state
    } catch (error) {
      console.error('Error fetching products:', error);
      setError('Une erreur est survenue lors de la récupération des produits.');
    } finally {
      setLoading(false);
    }
  };

  return { products, error, loading, search };
};
