import React from 'react';

const StoreGrid = ({ stores, onStoreClick }) => (
  <section className="py-12 bg-gray-100">
    <div className="container mx-auto grid grid-cols-2 sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-8 gap-6">
      {stores.map((storeItem, index) => (
        <div
          key={index}
          className="bg-white shadow-md rounded-lg p-4 text-center border border-gray-200 cursor-pointer"
          onClick={() => onStoreClick(storeItem)}
        >
          <img
            src={storeItem.image || 'https://dummyimage.com/300x300/E0D7FF/000.png&text='+encodeURI(storeItem.name)}
            alt={`Store ${storeItem.name}`}
            className="w-full h-auto rounded-lg mb-4"
          />
          <h3 className="text-lg font-semibold text-gray-800">{storeItem.name}</h3>
          {storeItem.address && <p className="text-gray-600"><strong>Adresse:</strong> {storeItem.address}</p>}
        </div>
      ))}
    </div>
  </section>
);

export default StoreGrid;
