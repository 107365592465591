import React, { useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ProductSearchBox from '../features/search/components/ProductSearchBox';
import ProductGrid from '../features/search/components/ProductGrid';
import ProductModal from '../features/search/components/ProductModal';
import { ErrorMessage, LoadingMessage } from '../components/Messages';
import { UseSearchProducts } from '../features/search/hooks/UseSearchProducts';

const ProductPage = () => {
  const { products, error, loading, search } = UseSearchProducts();
  const [filters, setFilters] = useState({
    category: '',
    priceRange: '',
    inStock: false,
  });

  const [selectedProduct, setSelectedProduct] = useState(null); // To track the selected product

  const handleSearch = (query) => {
    search(query, filters);
  };

  const handleProductClick = (product) => {
    setSelectedProduct(product); // Open modal with selected product info
  };

  const handleCloseModal = () => {
    setSelectedProduct(null); // Close modal
  };

  return (
    <div className="flex flex-col min-h-screen bg-white">
      <Header />
      <div className="flex-grow container mx-auto py-8">
        <ProductSearchBox onSearch={handleSearch} filters={filters} setFilters={setFilters} />
        <ErrorMessage error={error} />
        <LoadingMessage loading={loading} />
        {!loading && !error && (
          <div className="mt-8">
            <ProductGrid products={products} onProductClick={handleProductClick} />
          </div>
        )}
      </div>
      <Footer />
      {selectedProduct && (
        <ProductModal product={selectedProduct} onClose={handleCloseModal} />
      )}
    </div>
  );
};

export default ProductPage;
