import React from 'react';

const YatilGrid = ({ yatilTriplets }) => (
  <section className="results py-8">
    <div className="grid grid-cols-2 gap-6 sm:grid-cols-4 lg:grid-cols-6">
      {yatilTriplets.map((item, index) => (
        <div key={index} className="product-card bg-white p-4 border border-gray-300 rounded-lg shadow">
          <img className="rounded-lg w-full h-auto mb-4"
            src={item.product.image || 'https://dummyimage.com/300x300/E0D7FF/000.png&text='+encodeURI(item.product.name)}
            alt={`${item.product.name}`}
          />
          <h3 className="text-lg font-medium mb-2">{item.product.name} {item.product.brand}</h3>
          {item.product.quantity && <p className="text-sm"><strong>Quantité:</strong> {item.product.quantity}</p>}
          {item.product.description && <p className="text-sm mt-2"><strong>Description:</strong> {item.product.description}</p>}
          <p className="text-sm mt-2"><strong>Disponible chez:</strong> {item.store.name} - {item.store.address}</p>
        </div>
      ))}
    </div>
  </section>
);

export default YatilGrid;
