import React, { useState } from 'react';

const StoreSearch = ({ onStoreSelect }) => {
  const [storeQuery, setStoreQuery] = useState('');
  const [storeResults, setStoreResults] = useState([]);

  const handleStoreSearch = async (e) => {
    setStoreQuery(e.target.value);
    if (e.target.value.length > 2) {
      const response = await fetch(`/.netlify/functions/getStores?name=${encodeURIComponent(e.target.value)}`);
      const stores = await response.json();
      setStoreResults(stores);
    } else {
      setStoreResults([]);
    }
  };

  return (
    <div className="mb-4">
      <input
        type="text"
        placeholder="Rechercher un magasin..."
        value={storeQuery}
        onChange={handleStoreSearch}
        className="w-full px-4 py-2 border rounded-lg border-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-500"
      />
      <ul className="mt-2">
        {storeResults.map((store) => (
          <li
            key={store._id}
            onClick={() => onStoreSelect(store)}
            className="cursor-pointer py-2 px-4 hover:bg-gray-200 rounded-lg"
          >
            {store.name} - {store.address}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default StoreSearch;
