import { useState, useEffect } from 'react';

export const UseAdminStores = () => {
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchStores = async () => {
    try {
      const response = await fetch(`/.netlify/functions/getStores?limit=20`);
      if (!response.ok) {
        throw new Error(`Failed to fetch stores: ${response.statusText}`);
      }
      const data = await response.json();
      setStores(data);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStores();
  }, []);

  const handleSave = async (updatedStore) => {
    try {
      const response = await fetch(`/.netlify/functions/updateStore?id=${updatedStore._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedStore),
      });
      if (!response.ok) {
        throw new Error('Failed to update store');
      }
      fetchStores();
      setSelectedStore(null);
    } catch (error) {
      setError(error.message);
    }
  };

  // Geocode the address using a third-party API (Google Maps API or OpenStreetMap Nominatim)
  const geocodeAddress = async (address) => {
    try {
      // Replace with a geocoding service, e.g., Google Maps API or OpenStreetMap Nominatim
      const geocodingApiUrl = `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(address)}&format=json&limit=1`;

      const response = await fetch(geocodingApiUrl);
      const data = await response.json();

      if (data && data.length > 0) {
        const { lat, lon } = data[0];
        return {
          type: 'Point',
          coordinates: [parseFloat(lon), parseFloat(lat)],  // Return GeoJSON format
        };
      } else {
        throw new Error('Address not found');
      }
    } catch (error) {
      throw new Error('Failed to geocode address');
    }
  };

  return {
    stores,
    selectedStore,
    setSelectedStore,
    handleSave,
    geocodeAddress,  // Provide the geocoding function
    loading,
    error,
  };
};
