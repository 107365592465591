import React from 'react';

const ParentSearch = ({ parentSearch, searchResults, handleParentSearch, handleParentSelect }) => (
  <label className="block mb-4">
    Parent (Recherche par nom):
    <input
      className="mt-2 p-2 border border-gray-300 rounded w-full"
      type="text"
      value={parentSearch}
      onChange={handleParentSearch}
      placeholder="Rechercher un produit parent"
    />
    {searchResults.length > 0 && (
      <ul className="mt-2 bg-white border border-gray-300 rounded shadow-md">
        {searchResults.map((product) => (
          <li
            key={product._id}
            onClick={() => handleParentSelect(product)}
            className="p-2 cursor-pointer hover:bg-gray-200"
          >
            {product.name}
          </li>
        ))}
      </ul>
    )}
  </label>
);

export default ParentSearch;
