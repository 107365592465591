import React from 'react';

const Header = () => (
  <header className="flex justify-between items-center p-5 bg-gray-900 text-white">
    <a href="/" className="text-2xl font-bold">Yatil</a>
    <nav className="space-x-5">
      <a href="/products" className="hover:text-purple-400">Produits</a>
      <a href="/stores" className="hover:text-purple-400">Magasins</a>
    </nav>
  </header>
);

export default Header;
