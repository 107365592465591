import React from 'react';

const ProductList = ({ products, handleEditClick }) => (
  <div className="product-list">
    <table className="min-w-full bg-white border border-gray-300">
      <thead>
        <tr className="bg-gray-100">
          <th className="text-left py-2 px-4 border-b">Nom</th>
          <th className="text-left py-2 px-4 border-b">Quantité</th>
          <th className="text-left py-2 px-4 border-b">Description</th>
          <th className="text-left py-2 px-4 border-b">Concret</th>
          <th className="text-left py-2 px-4 border-b">Actions</th>
        </tr>
      </thead>
      <tbody>
        {products.map((product) => (
          <tr key={product._id} className="hover:bg-gray-100">
            <td className="py-2 px-4 border-b">{product.name}</td>
            <td className="py-2 px-4 border-b">{product.quantity}</td>
            <td className="py-2 px-4 border-b">{product.description}</td>
            <td className="py-2 px-4 border-b">{product.concrete ? 'Oui' : 'Non'}</td>
            <td className="py-2 px-4 border-b">
              <button
                onClick={() => handleEditClick(product)}
                className="bg-blue-500 text-white py-1 px-2 rounded hover:bg-blue-600"
              >
                Modifier
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

export default ProductList;
